export interface AskAiFrameConfigData {
  baseUrl: string;
  frameSources: ChatDashboardSection;
  cdnUrl: string;
}

const defaults: AskAiFrameConfigData = {
  baseUrl: '',
  cdnUrl: '',
  frameSources: {}
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
export interface AskAiFrameConfig extends AskAiFrameConfigData {}

export interface ChatDashboardSection {
  [key: string]: ChatDashboard;
}

export interface ChatDashboard {
  [key: string]: string;
}

export class AskAiFrameConfig {
  constructor(json?: AskAiFrameConfigData) {
    json = json ?? defaults;

    Object.assign(this, defaults, json, {
      cdnUrl: json.cdnUrl.trim(),
      frameSources: json.frameSources
    });
  }
}
