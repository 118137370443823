import { BehaviorSubject, identity, map, Observable, OperatorFunction } from 'rxjs';
import { ApiConfig } from '../app-config/api.config';
import { HttpEndpoint } from '../app-config/http-endpoint';
import { ApiFeatureFlag } from './feature-flags-service';

export interface FeatureFlagsConfig {
  /**
   * Base url of the http endpoint that will return array of `ApiFeatureFlag`
   *
   * Defaults to `ApiConfig.apiUrl`
   */
  baseUrl: string;

  /**
   * RxJs observable whose emission(s) will trigger a fetch of the feature flags
   *
   * Defaults to an observable that emits only once.
   */
  fetchTrigger$: Observable<never>;

  /**
   * RxJs operator to adjust the array of `ApiFeatureFlag` returned from the http endpoint
   *
   * Defaults to returning all flags "as is" from the http endpoint.
   * Note that this operator function will have access to injected services via the angular `inject()` function.
   *
   * @example
   * function selectFlags() {
   *   const authService = inject(AuthService);
   *   return (source$: Observable<ApiFeatureFlag[]>) =>
   *     source$.pipe(
   *       switchMap(flags =>
   *         authService.currentClientId$.pipe(
   *           isNotNullOrUndefined(),
   *           map(clientId => selectFeatureFlagsByClient(flags, clientId))
   *         )
   *       )
   *     );
   * }
   */
  selectFlags: () => OperatorFunction<ApiFeatureFlag[], ApiFeatureFlag[]>;

  /**
   * Url Path of the http endpoint that will return array of `ApiFeatureFlag`
   *
   * Defaults to 'featureflags'
   */
  path: string;
}

export class FeatureFlagsConfig extends HttpEndpoint {
  constructor(json: Partial<FeatureFlagsConfig> = {}, apiConfig: ApiConfig) {
    super(json, { baseUrl: apiConfig.url, path: 'featureflags' });
    const { baseUrl: _baseUrl, path: _path, ...rest } = json;
    this.selectFlags = selectAllFlags;
    this.fetchTrigger$ = new BehaviorSubject<never>(0 as never);
    Object.assign(this, rest);
  }
}

function selectAllFlags() {
  return (source$: Observable<ApiFeatureFlag[]>) => source$.pipe(map(identity));
}
