import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { IconBaseComponent } from './icon-base.component';

@Component({
  selector: 'mri-fld-icon',
  templateUrl: './icon-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class FieldIconComponent extends IconBaseComponent {
  constructor() {
    super();
    this.direction = 'right';
  }

  @HostBinding('class.mri-field__icon') readonly fieldIconHostClass = true;
  @HostBinding('class.mri-field__icon--left') get leftIcon() {
    return this.direction === 'left';
  }
  @HostBinding('class.mri-field__icon--right') get rightIcon() {
    return this.direction === 'right';
  }
}
