import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-loading',
  template: '',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class LoadingComponent {
  @HostBinding('class.loading')
  get loadingClass() {
    return this.show;
  }

  @HostBinding('attr.aria-busy')
  get busy() {
    return this.show;
  }

  @Input() show = false;
}
