import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconBaseComponent } from './icon-base.component';

@Component({
  selector: 'mri-icon-only',
  templateUrl: './icon-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class IconComponent extends IconBaseComponent {}
