import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, runInInjectionContext } from '@angular/core';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, of } from 'rxjs';
import { map, pluck, shareReplay, switchMap } from 'rxjs/operators';
import { FeatureFlagsConfig } from './feature-flags-config';

const isHttpErrorResponse = (error: unknown): error is HttpErrorResponse => error instanceof HttpErrorResponse;
const isNotFound = (error: HttpErrorResponse): boolean => error.status === 404;
//If the status code is a 404, there is very little chance of it coming back, so bail out early.
const shouldRetry = (error: unknown) => (isHttpErrorResponse(error) && !isNotFound(error)) || true;

export interface ApiFeatureFlag {
  name: keyof ValidFeatureFlags;
  enabled: boolean;
}

const areApiFeatureFlags = (responseData: unknown): responseData is ApiFeatureFlag[] =>
  Array.isArray(responseData) &&
  responseData.every(responseItem => {
    const flag = responseItem as ApiFeatureFlag;
    return flag.name !== undefined && flag.enabled !== undefined;
  });

export type FeatureFlagName = keyof ValidFeatureFlags;

export interface ValidFeatureFlags {
  exampleFlag: boolean;
  selfHosted: boolean;
  reconciliation: boolean;
  workspaceFilters: boolean;
  askAgora: boolean;
  askAgoraFrame: boolean;
  organisationDelete: boolean;
}

export class FeatureFlags implements ValidFeatureFlags {
  static allDisabled = new FeatureFlags();
  static allEnabledFlags: ApiFeatureFlag[] = Object.keys(FeatureFlags.allDisabled)
    .map(name => name as keyof ValidFeatureFlags)
    .map(name => ({ name, enabled: true }));
  static allEnabled = new FeatureFlags(FeatureFlags.allEnabledFlags);

  exampleFlag = false;
  selfHosted = false;
  reconciliation = false;
  workspaceFilters = false;
  askAgora = false;
  askAgoraFrame = false;
  organisationDelete = false;

  constructor(externalFlags: ApiFeatureFlag[] = []) {
    externalFlags
      .filter(({ name }) => Object.keys(this).includes(name))
      .forEach(({ name, enabled }) => {
        this[name] = enabled;
      });
  }
}

export const notFlag = (flag: FeatureFlagName) => `!${flag}`;

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  public flags$: Observable<FeatureFlags>;

  constructor(http: HttpClient, config: FeatureFlagsConfig, errorPolicy: ErrorPolicyService, injector: Injector) {
    const fetches$ = config.fetchTrigger$.pipe(
      switchMap(() =>
        http.get(config.url).pipe(
          retryBackoff({ initialInterval: 1000, maxRetries: 3, shouldRetry }),
          switchMap(apiFlags => {
            if (areApiFeatureFlags(apiFlags)) {
              return of(apiFlags).pipe(
                runInInjectionContext(injector, config.selectFlags),
                map(flags => new FeatureFlags(flags))
              );
            } else {
              return of(FeatureFlags.allDisabled);
            }
          }),
          errorPolicy.catchHandleReplace(FeatureFlags.allDisabled)
        )
      )
    );

    this.flags$ = fetches$.pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  isEnabled$(name: FeatureFlagName) {
    return this.flags$.pipe(pluck(name));
  }
}
