import { BaseWidgetConfig, BaseWidgetConfigData } from '../widget-config/base-widget-config';

export interface AskAIConfigData extends BaseWidgetConfigData {
  assetSource: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
export interface AskAIConfig extends AskAIConfigData {}

export class AskAIConfig extends BaseWidgetConfig {
  constructor(json?: AskAIConfigData) {
    super(json);
    const assetSource = json?.assetSource?.trim() ?? '';
    Object.assign(this, { assetSource });
  }
}
